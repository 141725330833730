<template>
	<div>
		<navComponents />
		<div class="nav-bgc"></div>
		<div class="tryuse1">
			<div class="w">
				<div class="tryuse1-flex">
					<div class="formbox">
						<iframe
							height="100%"
							allowTransparency="true"
							scrolling="no"
							style="display: block; min-width: 100%; width: 100px; border: none; overflow: auto"
							frameborder="0"
							src="http://infomiracle01.mikecrm.com/foedfiY"
						></iframe>
					</div>
					<div class="rightVidoe">
						<div class="radiobox"></div>
						<div class="p1">StaRiver RDP操作视频推荐</div>
						<div class="whitebook"></div>
						<div class="p2">
							<a href="https://element.eleme.cn/#/zh-CN/component/carousel">推荐下载 ｜ StaRiver RDP产品白皮书</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<CopyrightComponents />
	</div>
</template>

<script>
// import aaa from  './aaa'
import navComponents from "@/components/Nav/Nav.vue";
import CopyrightComponents from "@/components/Copyright/Copyright.vue";
export default {
	components: { navComponents, CopyrightComponents },
	props: {},
	data() {
		return {};
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
};
</script>

<style scoped lang="scss">
.nav-bgc {
	width: 1903.5px;
	height: 90px;
}
.nav-bgc {
	margin: 0 auto;
	background: url("~@/assets/images/tryuse-navbg.png") no-repeat center;
	background-size: 100% 100%;
}
.tryuse1 {
	height: 668px;
	background-color: #fff;
	margin: 0 auto;
	.w {
		width: 1500px;

		margin: 0 auto;
		overflow: hidden;
		.tryuse1-flex {
			padding-left: 15px;
			padding-right: 15px;
			margin-top: 40px;
			margin-bottom: 20px;
			display: flex;
			justify-content: space-between;
			.formbox {
				width: 1000px;
				height: calc(498px + 70px);
				background: #ffffff;
				box-shadow: 0px 6px 13px 2px rgba(4, 0, 0, 0.1);
			}
			.rightVidoe {
				width: 423px;
				.radiobox {
					width: 423px;
					height: 240px;
					background-color: pink;
				}
				.p1 {
					margin-top: 15px;
					text-align: center;
					font-size: 16px;
					font-family: "Microsoft YaHei";
				}
				.whitebook {
					width: 423px;
					height: 240px;
					background: url("~@/assets/images/tryuse-whitebook.png") no-repeat center;
					background-size: 100% 100%;
					margin-top: 54px;
				}
				.p2 {
					margin-top: 15px;
					text-align: center;
					font-size: 16px;
					font-family: "Microsoft YaHei";
					a {
						text-decoration: none;
						color: #000;
					}
				}
			}
		}
	}
}
</style>
